import React from "react";
import PageWrapper from "../components/Layout/PageWrapper";
import Categories from "../components/Home/Categories";
import Title from '../components/Title';
import { Col, Container } from "react-bootstrap";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <PageWrapper>
    <SEO title="404: Not found" />
    <Container className="d-flex justify-content-center under-nav text-center" fluid>
      <Col>
      <Title title="niet" subtitle="gevonden" />
      <h3>Helaas bestaat de pagina die je zocht niet.</h3>
      <Categories />
      </Col>
    </Container>
  </PageWrapper>
)

export default NotFoundPage
